// In Datenschutz.js
const Datenschutz = () => {
    return (
      <div>
        <h1>Datenschutz</h1>
        <p>Hier stehen die Datenschutzinformationen</p>
      </div>
    );
  };
  
  export default Datenschutz;
  