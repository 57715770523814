// src/pages/Impressum.js
import React from 'react';

const Impressum = () => {
  return (
    <div className="p-12 bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <h2 className="text-4xl font-bold text-blue-600 mb-8 text-center">Impressum</h2>
      <div className="text-gray-700 text-center max-w-4xl mx-auto">
        <p>
          Angaben gemäß § 5 TMG:
        </p>
        <p>
          krypto<br/>
          Hegelstraße 5<br/>
          39104 Magdeburg<br/>
        </p>
        <p>
          <strong>Kontakt:</strong><br/>
          Telefon: 01234-56789<br/>
          E-Mail: info@deinedomain.de<br/>
        </p>
        <p>
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:<br/>
          krypto<br/>
          Hegelstraße 5<br/>
        </p>
      </div>
    </div>
  );
};

export default Impressum;
