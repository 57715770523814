import React from 'react';
import { FaClock, FaBan, FaDollarSign, FaUserShield, FaBatteryThreeQuarters, FaNetworkWired, FaDatabase } from 'react-icons/fa';  // Zusätzliche Icons für die Bedenken

const Bedenken = () => {
  const concerns = [
    {
      title: 'Bildschirmzeit',
      icon: <FaClock />,
      description: 'Bei richtiger Planung und Kontrolle bleibt die Bildschirmzeit im gesunden Rahmen.',
      details: 'Lehrer können die Bildschirmzeit im Unterricht steuern und Pausen zur Erholung einplanen, um sicherzustellen, dass die Schüler nicht zu lange am Bildschirm sind.',
    },
    {
      title: 'Ablenkung',
      icon: <FaBan />,
      description: 'Durch klare Regeln und die richtige Wahl der Apps kann Ablenkung minimiert werden.',
      details: 'Mit der richtigen Auswahl an Lern-Apps und einer klaren Struktur im Unterricht kann sichergestellt werden, dass Schüler auf den Unterricht konzentriert bleiben.',
    },
    {
      title: 'Kosten',
      icon: <FaDollarSign />,
      description: 'Langfristig können Tablets Papierkosten senken und Schulen sparen Ressourcen.',
      details: 'Während die Anschaffung von Tablets eine Investition ist, können sie langfristig Kosten senken, indem der Papierverbrauch reduziert und Verwaltungsprozesse digitalisiert werden.',
    },
    {
      title: 'Datensicherheit',
      icon: <FaUserShield />,
      description: 'Moderne Sicherheitstechnologien schützen die Daten von Schülern und Lehrern.',
      details: 'Durch den Einsatz von verschlüsselten Netzwerken und sicheren Plattformen können Daten geschützt werden. Regelmäßige Schulungen für Lehrer und Schüler sorgen zudem für ein besseres Verständnis von Datenschutz.',
    },
    {
      title: 'Akkulaufzeit',
      icon: <FaBatteryThreeQuarters />,
      description: 'Moderne Tablets haben eine lange Akkulaufzeit und sind den ganzen Schultag einsatzbereit.',
      details: 'Tablets sind darauf ausgelegt, eine volle Schultag-Nutzung mit einer einzigen Aufladung zu überstehen. Schulen können Ladestationen einrichten, um die Akkulaufzeit zu verlängern.',
    },
    {
      title: 'Technische Probleme',
      icon: <FaNetworkWired />,
      description: 'Durch regelmäßige Wartung und Support können technische Probleme minimiert werden.',
      details: 'Schulen können IT-Support anbieten, um technische Schwierigkeiten schnell zu beheben. Zudem werden die meisten Tablets regelmäßig aktualisiert, um eine stabile Leistung zu gewährleisten.',
    },
    {
      title: 'Wartung und Reparaturkosten',
      icon: <FaDatabase />,
      description: 'Schulen können Wartungspläne und Schutzprogramme für Tablets anbieten.',
      details: 'Viele Schulen bieten Wartungsverträge und Versicherungen für Tablets an, um Reparaturkosten zu minimieren. Zudem werden oft robuste Schutzhüllen verwendet, um Schäden zu verhindern.',
    },
    {
      title: 'Zugang zu unangemessenen Inhalten',
      icon: <FaBan />,
      description: 'Filtersoftware kann installiert werden, um den Zugang zu unangemessenen Inhalten zu blockieren.',
      details: 'Schulen können durch den Einsatz von Filter- und Überwachungssoftware sicherstellen, dass Schüler nur auf altersgerechte und lehrplanrelevante Inhalte zugreifen können.',
    },
    {
      title: 'Fehlende soziale Interaktionen',
      icon: <FaUserShield />,
      description: 'Tablets können kollaboratives Lernen fördern und soziale Interaktionen unterstützen.',
      details: 'Es gibt viele Apps und Plattformen, die kollaboratives Lernen ermöglichen, wie Gruppenprojekte und Diskussionsforen, die die Interaktion zwischen Schülern fördern.',
    },
  ];

  return (
    <div className="p-12 bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <h2 className="text-4xl font-bold text-blue-600 mb-8 text-center">Häufige Bedenken</h2>
      <p className="text-gray-700 text-center mb-12">
        Viele Eltern und Lehrer haben Bedenken, wenn es um die Nutzung von Tablets im Unterricht geht. Hier sind einige 
        häufige Bedenken und unsere Antworten darauf:
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {concerns.map((concern, index) => (
          <div
            key={index}
            className="relative bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          >
            <div className="flex items-center space-x-4">
              <div className="text-4xl text-blue-500">{concern.icon}</div>
              <div>
                <h3 className="text-2xl font-semibold text-gray-800">{concern.title}</h3>
                <p className="text-gray-700 mt-2">{concern.description}</p>
              </div>
            </div>
            {/* Erweiterte Erklärung bei Hover oder Klick */}
            <div className="mt-4 text-gray-600 text-sm">
              {concern.details}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Bedenken;
