import React from 'react';
import { useParams } from 'react-router-dom';

const resources = [
  {
    id: 1,
    title: 'PDF-Handbuch für Lehrer',
    description: 'Ein umfassendes Handbuch zur Nutzung von Tablets im Unterricht.',
    type: 'pdf',
    fileUrl: '/path/to/pdf-file.pdf',
  },
  {
    id: 2,
    title: 'Erklärvideo: Tablets im Klassenzimmer',
    description: 'Ein informatives Video über den Einsatz von Tablets im Unterricht.',
    type: 'video',
    fileUrl: '/path/to/video-file.mp4',
  },
  // Weitere Ressourcen...
];

const ResourceDetail = () => {
  const { id } = useParams();
  const resource = resources.find((res) => res.id === parseInt(id));

  return (
    <div className="p-8">
      <h1 className="text-4xl font-bold mb-6">{resource.title}</h1>
      <p className="text-gray-700 mb-6">{resource.description}</p>

      {resource.type === 'pdf' ? (
        <iframe src={resource.fileUrl} width="100%" height="600px" title={resource.title}></iframe>
      ) : resource.type === 'video' ? (
        <video src={resource.fileUrl} controls width="100%" />
      ) : null}

      <a
        href={resource.fileUrl}
        download
        className="block mt-6 bg-yellow-500 text-white px-6 py-3 rounded-md hover:bg-yellow-600 transition duration-300"
      >
        Download
      </a>
    </div>
  );
};

export default ResourceDetail;
