import React from 'react';
import { FaCheckCircle, FaBook, FaChalkboardTeacher, FaClock, FaUserShield, FaUsers, FaWifi, FaTabletAlt, FaHandsHelping } from 'react-icons/fa';

const Regeln = () => {
  const rules = [
    {
      icon: <FaBook />,
      title: 'Integration in den Unterrichtsplan',
      description: 'Tablets sollten gezielt in das pädagogische Konzept eingebunden werden, um den Lernerfolg zu maximieren.',
    },
    {
      icon: <FaCheckCircle />,
      title: 'Gezielte Nutzung von Apps',
      description: 'Nutzen Sie Apps, die den Lehrplan unterstützen und einen Mehrwert für den Unterricht bieten.',
    },
    {
      icon: <FaChalkboardTeacher />,
      title: 'Medienkompetenz der Lehrer',
      description: 'Es ist wichtig, dass Lehrer die notwendigen Kenntnisse besitzen, um Tablets effektiv im Unterricht einzusetzen.',
    },
    {
      icon: <FaUsers />,
      title: 'Eltern einbinden',
      description: 'Eltern sollten über die Nutzung der Tablets informiert und in den Prozess eingebunden werden.',
    },
    {
      icon: <FaClock />,
      title: 'Kontrolle der Bildschirmzeit',
      description: 'Achten Sie darauf, dass die Schüler nicht zu viel Zeit am Bildschirm verbringen, um Überbeanspruchung zu vermeiden.',
    },
    {
      icon: <FaUserShield />,
      title: 'Datenschutz und Sicherheit',
      description: 'Stellen Sie sicher, dass alle Datenschutzrichtlinien eingehalten werden, um die Schüler zu schützen.',
    },
    {
      icon: <FaWifi />,
      title: 'Zuverlässige Internetverbindung',
      description: 'Eine stabile Internetverbindung ist entscheidend für den reibungslosen Einsatz von Tablets im Unterricht.',
    },
    {
      icon: <FaTabletAlt />,
      title: 'Pflege und Wartung der Geräte',
      description: 'Stellen Sie sicher, dass die Tablets regelmäßig gewartet und aktualisiert werden, um optimale Leistung zu gewährleisten.',
    },
    {
      icon: <FaHandsHelping />,
      title: 'Teamarbeit fördern',
      description: 'Nutzen Sie Tablets, um die Zusammenarbeit zwischen Schülern zu fördern, etwa durch gemeinsame Projekte und Präsentationen.',
    },
  ];

  return (
    <div className="p-12 bg-gradient-to-b from-gray-100 to-gray-300 min-h-screen">
      <h2 className="text-4xl font-bold text-blue-600 mb-8 text-center">Richtiger Einsatz von Tablets im Unterricht</h2>
      <p className="text-gray-700 text-center mb-12">
        Um den maximalen Nutzen von Tablets im Unterricht zu erreichen, ist es wichtig, einige grundlegende Regeln und Richtlinien zu beachten.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {rules.map((rule, index) => (
          <div
            key={index}
            className="relative bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          >
            <div className="flex items-center space-x-4">
              <div className="text-4xl text-blue-500">{rule.icon}</div>
              <div>
                <h3 className="text-2xl font-semibold text-gray-800">{rule.title}</h3>
                <p className="text-gray-700 mt-2">{rule.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Regeln;
