import React from 'react';

const resources = [
  {
    id: 1,
    title: 'PDF-Handbuch für Lehrer',
    description: 'Ein umfassendes Handbuch zur Nutzung von Tablets im Unterricht.',
    type: 'pdf',
    fileUrl: '/pdfs/Handbuch.pdf',
  },
  {
    id: 2,
    title: 'Apps für den Unterricht nach Fächern',
    description: 'Ein umfassendes Handbuch zur Nutzung von Tablets im Unterricht.',
    type: 'pdf',
    fileUrl: '/pdfs/Apps für den Unterricht nach Fächern.pdf',
  },
  {
    id: 3,
    title: 'Regelwerk Tabletnutzung',
    description: 'Ein umfassendes Handbuch zu möglichen Tabletregeln.',
    type: 'pdf',
    fileUrl: '/pdfs/Regelwerk_Tabletnutzung.pdf',
  },
  {
    id: 4,
    title: 'Tablet Regeln',
    description: 'Ein umfassendes Handbuch zu möglichen Tabletregeln.',
    type: 'pdf',
    fileUrl: '/pdfs/Tablet Regeln.pdf',
  },
  {
    id: 5,
    title: 'Betriebssysteme für Tablets',
    description: 'Welche Betriebssysteme für Tablets gibt es?',
    type: 'pdf',
    fileUrl: '/pdfs/OS.pdf',
  },
  {
    id: 6,
    title: 'Tablets - Die Zukunft des Lernens',
    description: 'Tablets in Schulen - Die Zukunft des Lernens',
    type: 'pdf',
    fileUrl: '/pdfs/Tablets-in-Schulen-Die-Zukunft-des-Lernens.pdf',
  },
  {
    id: 7,
    title: 'Video: 6 Ways to Use Your iPad as a Teacher',
    description: 'Ein informatives Video über den Einsatz von Tablets im Unterricht.',
    type: 'video',
    fileUrl: 'https://www.youtube.com/embed/OEzGfYkmSnc?si=C88tDSvgMVljIxaL&amp;start=122',
  },
  {
    id: 8,
    title: 'Stift, Heft & Mappe oder digital in der Schule? Wo liegen Vorteile und Nachteile?',
    description: 'Ein informatives Video über den Einsatz von Tablets im Unterricht.',
    type: 'video',
    fileUrl: 'https://www.youtube.com/embed/lN1Oa-XL4iA?si=i8acI2x7w1F3Vwr2',
  },
  {
    id: 9,
    title: 'Tablet statt Bücher: Schule digital | Gut zu wissen | BR',
    description: 'Ein informatives Video über den Einsatz von Tablets im Unterricht.',
    type: 'video',
    fileUrl: 'https://www.youtube.com/embed/JIXNzC1Il_Q?si=I8may4_oVvihUsyt',
  },
  {
    id: 10,
    title: 'Tablets Vor und Nachteile',
    description: 'Vorteile und Nachteile von Tablets im Unterricht',
    type: 'website',
    fileUrl: 'https://blog.cortado.com/de/tablets-im-unterricht-vorteile-nachteile/',
  },
  {
    id: 11,
    title: 'Tablets im Unterricht',
    description: 'Tablets im Unterricht - Darum ist der Einsatz an Schulen sinnvoll',
    type: 'website',
    fileUrl: 'https://www.gecko.de/wissenshub/tablets-im-unterricht-darum-ist-der-einsatz-an-schulen-sinnvoll/',
  },
  {
  id: 12,
  title: 'Regeln für Tablets',
  description: 'Tablets im Unterricht - Wie führe ich Verhaltensregeln ein',
  type: 'website',
  fileUrl: 'https://www.lmz-bw.de/landesmedienzentrum/aktuelles/aktuelle-meldungen/detailseite/tablets-im-unterricht-wie-fuehre-ich-verhaltensregeln-ein',
},
];

const Home = () => {
  return (
    <div className="p-8">
      <h1 className="text-4xl font-bold mb-8 text-center">Ressourcenübersicht</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {resources.map((resource) => (
          <div
            key={resource.id}
            className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:-translate-y-2 hover:shadow-2xl"
          >
            <h2 className="text-2xl font-bold mb-2">{resource.title}</h2>
            <p className="text-gray-700 mb-4">{resource.description}</p>
            {resource.type === 'pdf' ? (
              <a
                href={resource.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-6 py-3 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 transition-colors duration-300"
              >
                PDF ansehen
              </a>
            ) : resource.type === 'video' ? (
              <a
                href={resource.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-6 py-3 bg-green-500 text-white font-semibold rounded-md hover:bg-green-600 transition-colors duration-300"
              >
                Video ansehen
              </a>
            ) : (
              <a
                href={resource.fileUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-6 py-3 bg-purple-500 text-white font-semibold rounded-md hover:bg-purple-600 transition-colors duration-300"
              >
                Website besuchen
              </a>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Home;
