import React, { useState } from 'react';

const Vorteile = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  return (
    <div className="p-12 bg-gradient-to-b from-blue-100 to-beige-100 min-h-screen flex flex-col items-center">
      <h2 className="text-5xl font-bold text-gray-900 mb-12 text-center">
        Die Vorteile von Tablets im Unterricht
      </h2>

      {/* Grid with images and hover effects */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
        <div className="group relative">
          <img
            src="/images/customized.png"
            alt="Personalisierte Lerninhalte"
            className="w-full h-64 object-cover rounded-md shadow-lg transform group-hover:scale-105 transition-transform duration-300 cursor-pointer"
          />
          <div className="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 flex items-center justify-center text-white font-semibold text-lg transition-opacity duration-300 p-4 text-center">
            Personalisierte Lerninhalte: Der Unterricht kann individuell auf die Bedürfnisse der Schüler angepasst werden. Lehrer können flexibel auf Stärken und Schwächen reagieren.
          </div>
        </div>
        <div className="group relative">
          <img
            src="/images/multimedia.png"
            alt="Multimedia-Projekte"
            className="w-full h-64 object-cover rounded-md shadow-lg transform group-hover:scale-105 transition-transform duration-300 cursor-pointer"
          />
          <div className="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 flex items-center justify-center text-white font-semibold text-lg transition-opacity duration-300 p-4 text-center">
            Multimedia-Projekte: Kreative Projekte mit Text, Bild, Video und interaktiven Elementen fördern das tiefere Verständnis und die Kreativität der Schüler.
          </div>
        </div>
        <div className="group relative">
          <img
            src="/images/env.png"
            alt="Umweltfreundlich"
            className="w-full h-64 object-cover rounded-md shadow-lg transform group-hover:scale-105 transition-transform duration-300 cursor-pointer"
          />
          <div className="absolute inset-0 bg-black bg-opacity-60 opacity-0 group-hover:opacity-100 flex items-center justify-center text-white font-semibold text-lg transition-opacity duration-300 p-4 text-center">
            Umweltfreundlich: Der digitale Unterricht reduziert den Papierverbrauch erheblich und fördert ein nachhaltigeres Lernen.
          </div>
        </div>
      </div>

      {/* Modern styled table */}
      <div className="w-full max-w-4xl mb-12">
        <table className="table-auto w-full bg-white rounded-md shadow-lg overflow-hidden">
          <thead>
            <tr className="bg-gray-800 text-white">
              <th className="p-4 text-left">Vorteil</th>
              <th className="p-4 text-left">Beschreibung</th>
            </tr>
          </thead>
          <tbody className="text-gray-700">
            <tr className="hover:bg-gray-100 transition-colors">
              <td className="p-4 font-semibold text-gray-900">Personalisierte Lerninhalte</td>
              <td className="p-4">Der Unterricht kann individuell auf die Bedürfnisse der Schüler angepasst werden.</td>
            </tr>
            <tr className="hover:bg-gray-100 transition-colors">
              <td className="p-4 font-semibold text-gray-900">Direkter Zugang</td>
              <td className="p-4">Schüler haben direkten Zugriff auf aktuelle und vielseitige Lernmaterialien.</td>
            </tr>
            <tr className="hover:bg-gray-100 transition-colors">
              <td className="p-4 font-semibold text-gray-900">Multimedia-Projekte</td>
              <td className="p-4">Die Integration von Text, Bild, Video und interaktiven Elementen fördert die Kreativität.</td>
            </tr>
            <tr className="hover:bg-gray-100 transition-colors">
              <td className="p-4 font-semibold text-gray-900">Interaktive Lern-Apps</td>
              <td className="p-4">Lern-Apps und Gamification steigern die Motivation der Schüler.</td>
            </tr>
            <tr className="hover:bg-gray-100 transition-colors">
              <td className="p-4 font-semibold text-gray-900">Umweltfreundlich</td>
              <td className="p-4">Der Einsatz von Tablets reduziert den Papierverbrauch und schont die Umwelt.</td>
            </tr>
          </tbody>
        </table>
      </div>

      {/* FAQ-Bereich mit aufklappbaren Fragen und schönem Design */}
      <div className="w-full max-w-4xl mb-12">
        <h3 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Häufige Fragen</h3>
        <div className="bg-white p-6 rounded-md shadow-lg">
          {[
            {
              question: 'Sind Tablets im Unterricht für alle Altersgruppen geeignet?',
              answer: 'Ja, Tablets können in allen Altersgruppen verwendet werden, von der Grundschule bis zur Oberstufe. Es gibt spezielle Lern-Apps und Inhalte für verschiedene Altersstufen.',
            },
            {
              question: 'Wie können Tablets umweltfreundlicher sein?',
              answer: 'Durch den Einsatz von Tablets wird der Papierverbrauch reduziert, da Arbeitsblätter, Lehrmaterialien und Bücher digital bereitgestellt werden können.',
            },
            {
              question: 'Sind Tablets im Unterricht sicher?',
              answer: 'Ja, es gibt verschiedene Sicherheitsvorkehrungen, die den sicheren Einsatz von Tablets gewährleisten, darunter Jugendschutzfilter und schulinterne Sicherheitsmaßnahmen.',
            },
          ].map((faq, index) => (
            <div key={index} className="mb-4 border-b pb-4">
              <h4
                className="font-semibold text-gray-900 cursor-pointer flex justify-between items-center"
                onClick={() => toggleFAQ(index)}
              >
                {faq.question}
                <span className={`ml-4 transform transition-transform ${openFAQ === index ? 'rotate-180' : ''}`}>
                  ▼
                </span>
              </h4>
              {openFAQ === index && <p className="text-gray-700 mt-2">{faq.answer}</p>}
            </div>
          ))}
        </div>
      </div>

      {/* Call to Action */}
      <div className="w-full max-w-4xl text-center">
      <a href="https://www.gecko.de/wissenshub/tablets-im-unterricht-darum-ist-der-einsatz-an-schulen-sinnvoll/" target="_blank" rel="noopener noreferrer">
  <button className="bg-blue-500 text-white px-6 py-3 rounded-md shadow-lg hover:bg-blue-600 transition-all duration-300">
    Mehr über den Einsatz von Tablets im Unterricht erfahren
  </button>
</a>

    
      </div>
    </div>
  );
};

export default Vorteile;
