import React, { useState } from 'react';
import { FaBook, FaTools, FaChalkboardTeacher, FaLightbulb, FaGamepad, FaUsers, FaGlobe, FaProjectDiagram, FaVrCardboard } from 'react-icons/fa';

const Einsatzmoeglichkeiten = () => {
  // State to control hover info
  const [hoveredItem, setHoveredItem] = useState(null);

  const items = [
    {
      title: 'Lern-Apps und interaktive Tools',
      description: 'Tablets ermöglichen den Einsatz von Lern-Apps wie Duolingo oder Geogebra, um Schüler individuell zu fördern.',
      icon: <FaTools />,
      details: 'Lern-Apps bieten die Möglichkeit, den Unterricht durch interaktive Übungen und personalisierte Aufgaben zu ergänzen, was den Schülern ein besseres Verständnis und vertieftes Lernen ermöglicht.',
    },
    {
      title: 'Digitale Lehrbücher und Materialien',
      description: 'Statt schwerer Bücher haben Schüler Zugriff auf digitale Bücher, was die Materialien immer aktuell hält.',
      icon: <FaBook />,
      details: 'Mit digitalen Lehrbüchern haben Schüler jederzeit Zugriff auf aktualisierte Inhalte, wodurch der Unterricht flexibler und auf dem neuesten Stand gehalten wird.',
    },
    {
      title: 'Virtuelle Zusammenarbeit',
      description: 'Schüler können gemeinsam in Echtzeit an Dokumenten arbeiten, z.B. über Google Docs.',
      icon: <FaUsers />,
      details: 'Durch Cloud-basierte Zusammenarbeitstools können Schüler in Echtzeit an Gruppenprojekten arbeiten und Feedback von Lehrern und Mitschülern erhalten.',
    },
    {
      title: 'Recherche und Informationsbeschaffung',
      description: 'Schnelles Abrufen von Informationen und aktuellem Wissen durch das Internet.',
      icon: <FaGlobe />,
      details: 'Das Internet bietet eine riesige Datenbank an Wissen, die Schüler für ihre Projekte, Hausaufgaben oder Recherche nutzen können, um aktuelle und relevante Informationen zu finden.',
    },
    {
      title: 'Individuelle Förderung',
      description: 'Lehrer können Lernprogramme auswählen, die auf das individuelle Niveau der Schüler abgestimmt sind.',
      icon: <FaChalkboardTeacher />,
      details: 'Individuelle Lernprogramme und Anpassungen bieten Lehrern die Möglichkeit, auf das Tempo und Niveau jedes einzelnen Schülers einzugehen.',
    },
    {
      title: 'Multimedia-Präsentationen',
      description: 'Erstellung von Videos, Präsentationen und Animationen für den Unterricht.',
      icon: <FaLightbulb />,
      details: 'Schüler können durch Multimedia-Präsentationen kreativ werden und komplexe Inhalte durch Videos, Animationen oder interaktive Grafiken verständlich darstellen.',
    },
    {
      title: 'Gamification im Unterricht',
      description: 'Lernspiele und interaktive Aufgaben fördern die Motivation der Schüler.',
      icon: <FaGamepad />,
      details: 'Lernspiele und Gamification-Elemente bringen Spaß in den Unterricht und fördern gleichzeitig das Verständnis und die Motivation der Schüler.',
    },
    {
      title: 'Kollaboratives Lernen',
      description: 'Schüler können zusammen in Echtzeit an Projekten arbeiten und Feedback austauschen.',
      icon: <FaProjectDiagram />,
      details: 'Kollaboratives Lernen über Cloud-Tools fördert Teamarbeit und ermöglicht es Schülern, gemeinsam an Aufgaben zu arbeiten, unabhängig von ihrem Standort.',
    },
    {
      title: 'Augmented Reality (AR)',
      description: 'Virtuelle Welten und erweiterte Realität bringen komplexe Konzepte zum Leben.',
      icon: <FaVrCardboard />,
      details: 'Mit AR-Apps können Schüler durch erweiterte Realität virtuelle Lernwelten betreten, um historische Ereignisse, wissenschaftliche Prozesse und mehr hautnah zu erleben.',
    },
  ];

  return (
    <div className="p-12 bg-gradient-to-b from-blue-100 to-purple-200 min-h-screen">
      <h2 className="text-5xl font-bold text-gray-900 mb-12 text-center">
        Einsatzmöglichkeiten von Tablets im Schulunterricht
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {items.map((item, index) => (
          <div
            key={index}
            onMouseEnter={() => setHoveredItem(index)}
            onMouseLeave={() => setHoveredItem(null)}
            className="relative bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          >
            <div className="flex items-center space-x-4">
              <div className="text-4xl text-blue-500">{item.icon}</div>
              <div>
                <h3 className="text-2xl font-semibold text-gray-800">{item.title}</h3>
                <p className="text-gray-700 mt-2">{item.description}</p>
              </div>
            </div>
            {/* Show hover details */}
            {hoveredItem === index && (
              <div className="absolute inset-0 bg-blue-200 p-4 rounded-lg text-gray-800 transition-opacity duration-300 opacity-95">
                <p>{item.details}</p>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="text-center mt-16">
        <h3 className="text-3xl font-bold text-gray-800">Weitere Ressourcen</h3>
        <p className="text-gray-600 mt-4 mb-8">
          Entdecke weitere Einsatzmöglichkeiten und Materialien zur Unterstützung des digitalen Unterrichts.
        </p>
        <a
          href="https://www.oercommons.org"
          target="_blank"
          rel="noopener noreferrer"
          className="bg-purple-600 text-white px-6 py-3 rounded-md hover:bg-purple-700 transition duration-300"
        >
          Zu den Ressourcen
        </a>
      </div>
    </div>
  );
};

export default Einsatzmoeglichkeiten;
