import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './components/Header'; 
import Home from './pages/Home'; 
import Vorteile from './pages/Vorteile'; 
import Einsatzmoeglichkeiten from './pages/Einsatzmoeglichkeiten'; 
import Regeln from './pages/Regeln'; 
import Apps from './pages/Apps'; 
import Bedenken from './pages/Bedenken'; 
import Ressourcen from './pages/Ressourcen';
import RessourcenDetail from './pages/RessourcenDetail';
import Impressum from './pages/impressum';
import Datenschutz from './pages/Datenschutz';
import Kontakt from './pages/kontakt';
import './index.css';  

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <div className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/vorteile" element={<Vorteile />} />
            <Route path="/einsatzmoeglichkeiten" element={<Einsatzmoeglichkeiten />} />
            <Route path="/regeln" element={<Regeln />} />
            <Route path="/apps" element={<Apps />} />
            <Route path="/bedenken" element={<Bedenken />} />
            <Route path="/ressourcen" element={<Ressourcen />} />
            <Route path="/ressourcendetail" element={<RessourcenDetail />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutz" element={<Datenschutz />} />
            <Route path="/kontakt" element={<Kontakt />} /> 
          </Routes>
        </div>

        {/* Footer */}
        <footer className="bg-gray-800 text-white py-4 mt-auto">
          <div className="container mx-auto text-center">
            <p>&copy; 2024 Tablets im Unterricht. Alle Rechte vorbehalten.</p>
            <div className="flex justify-center space-x-4 mt-4">
              {/* Verlinkungen zu den jeweiligen Seiten */}
              <Link to="/impressum" className="hover:text-yellow-500">
                Impressum
              </Link>
              <Link to="/datenschutz" className="hover:text-yellow-500">
                Datenschutz
              </Link>
              <Link to="/kontakt" className="hover:text-yellow-500">
                Kontakt
              </Link>
            </div>
          </div>
        </footer>
      </div>
    </Router>
  );
}

export default App;
