import React from 'react';
import { NavLink } from 'react-router-dom';

const Header = () => {
  const navItems = [
    { name: 'Home', path: '/' },
    { name: 'Vorteile', path: '/vorteile' },
    { name: 'Einsatzmöglichkeiten', path: '/einsatzmoeglichkeiten' },
    { name: 'Regeln', path: '/regeln' },
    { name: 'Apps', path: '/apps' },
    { name: 'Bedenken', path: '/bedenken' },
    { name: 'Ressourcen', path: '/ressourcen' },
  ];

  return (
    <header className="bg-beige-300 p-6 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <NavLink
          to="/"
          className="text-gray-800 text-3xl font-extrabold tracking-wide hover:text-yellow-500 transition duration-300"
        >
          Tablets in der Schule
        </NavLink>
        <nav>
          <ul className="flex space-x-8">
            {navItems.map((item, index) => (
              <li key={index}>
                <NavLink
                  to={item.path}
                  className={({ isActive }) =>
                    isActive
                      ? 'relative text-yellow-500 font-bold text-lg uppercase tracking-wider'
                      : 'relative text-gray-800 hover:text-yellow-500 transition-all duration-300 text-lg font-semibold uppercase tracking-wider'
                  }
                >
                  {item.name}
                  <span className="absolute bottom-0 left-0 w-full h-1 bg-yellow-500 scale-x-0 hover:scale-x-100 transition-transform duration-300 origin-left"></span>
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
