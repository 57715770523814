import React from 'react';

const Home = () => {
  return (
    <div>
      {/* Hero Section */}
      <section className="hero bg-cover bg-center h-screen" style={{ backgroundImage: "url('/images/head.png')" }}>
        <div className="container mx-auto h-full flex flex-col justify-center items-center text-center">
          <div className="bg-black bg-opacity-50 p-8 rounded-md">
            {/* Halbtransparenter Hintergrund */}
            <h1 className="text-5xl font-bold text-white">Tablets im Unterricht – Die Zukunft des Lernens</h1>
            <p className="mt-4 text-lg text-white">
              Effektiver und kreativer Unterricht durch den richtigen Einsatz von Tablets
            </p>
            <button className="mt-6 bg-yellow-500 text-white px-6 py-2 rounded-md text-lg hover:bg-yellow-600 transition duration-300">
              Mehr erfahren
            </button>
          </div>
        </div>
      </section>

      {/* Vorteile von Tablets */}
      <section className="p-12 bg-beige-200">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold text-gray-800 mb-8">Vorteile von Tablets im Unterricht</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div>
              <img src="/images/customized.png" alt="Personalisierter Unterricht" className="w-full h-64 object-contain mb-4" />
              <h3 className="text-xl font-semibold text-gray-800">Personalisierter Unterricht</h3>
              <p className="mt-2 text-gray-700">Individuell angepasste Lerninhalte fördern die Schüler optimal.</p>
            </div>
            <div>
              <img src="/images/multimedia.png" alt="Multimedia-Unterricht" className="w-full h-64 object-contain mb-4" />
              <h3 className="text-xl font-semibold text-gray-800">Multimedia-Inhalte</h3>
              <p className="mt-2 text-gray-700">Tablets ermöglichen den kreativen Einsatz von Videos, Bildern und interaktiven Übungen.</p>
            </div>
            <div>
              <img src="/images/env.png" alt="Umweltfreundlich" className="w-full h-64 object-contain mb-4" />
              <h3 className="text-xl font-semibold text-gray-800">Umweltfreundlich</h3>
              <p className="mt-2 text-gray-700">Digitales Lernen spart Papier und schont die Umwelt.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Kreative Nutzung */}
      <section className="p-12 bg-beige-100">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-semibold text-gray-800 mb-8">Die richtige Nutzung macht den Unterschied</h2>
          <p className="text-lg text-gray-700 mb-8">
            Tablets können den Unterricht revolutionieren – aber nur, wenn sie richtig eingesetzt werden. Wir bieten Ihnen die besten Tipps und Tools, um das Beste aus dieser Technologie herauszuholen.
          </p>
          <div className="flex justify-center">
            <img src="/images/class2.png" alt="Richtige Nutzung im Unterricht" className="w-full max-w-5xl h-auto object-contain mb-4" />
          </div>
        </div>
      </section>


    </div>
  );
};

export default Home;
