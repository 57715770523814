import React from 'react';
import { FaLanguage, FaCalculator, FaClipboardList, FaGamepad, FaChalkboardTeacher, FaPencilAlt } from 'react-icons/fa'; // FaPencilAlt statt FaPen

// Beispielbilder zu den Apps
import DuolingoImage from '../components/duo.jpg';
import GeogebraImage from '../components/geo.png';
import QuizletImage from '../components/quiz.jpg';
import KahootImage from '../components/kah.png';
import MicrosoftClassroomImage from '../components/mic.png'; // Microsoft statt Google Classroom
import ExplainEverythingImage from '../components/ex.jpg';

const Apps = () => {
  const apps = [
    {
      name: 'Duolingo',
      description: 'Sprachen lernen mit Spaß.',
      icon: <FaLanguage />,
      image: DuolingoImage,
      url: 'https://www.duolingo.com', // Website von Duolingo
    },
    {
      name: 'Geogebra',
      description: 'Mathe interaktiv erleben.',
      icon: <FaCalculator />,
      image: GeogebraImage,
      url: 'https://www.geogebra.org', // Website von Geogebra
    },
    {
      name: 'Quizlet',
      description: 'Karteikarten zum Lernen erstellen.',
      icon: <FaClipboardList />,
      image: QuizletImage,
      url: 'https://quizlet.com', // Website von Quizlet
    },
    {
      name: 'Kahoot',
      description: 'Lernspiele für den Unterricht.',
      icon: <FaGamepad />,
      image: KahootImage,
      url: 'https://kahoot.com', // Website von Kahoot
    },
    {
      name: 'Microsoft 365',
      description: 'Organisieren und Verteilen von Materialien.',
      icon: <FaChalkboardTeacher />,
      image: MicrosoftClassroomImage,
      url: 'https://www.microsoft.com/de-de/microsoft-365?market=de', // Website von Microsoft Classroom
    },
    {
      name: 'Explain Everything',
      description: 'Interaktives Whiteboard für den Unterricht.',
      icon: <FaPencilAlt />,
      image: ExplainEverythingImage,
      url: 'https://explaineverything.com', // Website von Explain Everything
    },
  ];

  return (
    <div className="p-12 bg-gradient-to-b from-blue-50 to-blue-100 min-h-screen">
      <h2 className="text-4xl font-bold text-blue-600 mb-8 text-center">Nützliche Apps für den Unterricht</h2>
      <p className="text-gray-700 text-center mb-12">
        Hier sind einige der besten Apps, die im Unterricht verwendet werden können, um das Lernen interaktiv und spannend zu gestalten.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {apps.map((app, index) => (
          <a
            key={index}
            href={app.url}
            target="_blank"
            rel="noopener noreferrer"
            className="relative bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300 cursor-pointer"
          >
            <div className="overflow-hidden rounded-md mb-4">
              <img
                src={app.image}
                alt={app.name}
                className="w-full h-40 object-cover transition-transform duration-300 transform hover:scale-105"
              />
            </div>
            <div className="flex items-center space-x-4">
              <div className="text-4xl text-blue-500">{app.icon}</div>
              <div>
                <h3 className="text-2xl font-semibold text-gray-800">{app.name}</h3>
                <p className="text-gray-700 mt-2">{app.description}</p>
              </div>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
};

export default Apps;
